import { Route, Routes } from 'react-router-dom';
import './App.css';

import MainLayout from '../src/Screens/Layouts/MainLayout';
import Home from './Screens/Homepage/Root';

import Debug from './Screens/Debug/Debug';

/////////////////////////////////       ABOUT           /////////////////////////////////
import About from './Screens/About/About';
import Bio from './Screens/About/About_Screens/Bio';
import Awards from './Screens/About/About_Screens/Awards';

/////////////////////////////////       GALLERY           ////////////////////////////////
import Artwork from './Screens/Gallery/Layout/Artwork';
import Featured from './Screens/Gallery/Featured/Featured';
import Shows from './Screens/Gallery/Galleries/Shows';
import AtWork from './Screens/Gallery/Work/AtWork';
import Clients from './Screens/Gallery/HappyClients/Clients';


/////////////////////////////////       MEMORIALS           //////////////////////////////////
import Memorials from './Screens/Memorials/Memorials';


/////////////////////////////////       PRESS           //////////////////////////////////
import Press from './Screens/Press/Press_Screens/Press';

/////////////////////////////////       INSTALLATIONS          ///////////////////////////
import Installations from './Screens/Installations/Installations';

/////////////////////////////////       SHOP           //////////////////////////////////
import Shop from './Screens/Shop/Shop';
import ShopDisplay from './Screens/Shop/Shop_Components/ShopDisplay';
import ItemDisplay from './Screens/Shop/Shop_Components/ItemDisplay';
import ShopViewItems from './Screens/Dashboard/ShopScreens/ShopViewItems';
import EditItem from './Screens/Dashboard/ShopScreens/EditItem';
import ShopUnderConstruction from './Screens/Shop/ShopUnderConstruction';

import Checkout from './Screens/Shop/Payments/PaymentForm';

import UploadFailure from './Screens/Dashboard/ItemUpload/UploadFailure';
import UploadSuccess from './Screens/Dashboard/ItemUpload/UploadSuccess';

/////////////////////////////////       CONTACT          //////////////////////////////////
import Contact from './Screens/Contact/Contact';

import Login from './Screens/Login/Login';
import DashLayout from './Screens/Dashboard/Layout/DashLayout';
import React from 'react';
import PersistLogin from './Components/Auth/PersistLogin';
import RequireAuth from './Components/Auth/RequireAuth';
import ShopAdd from './Screens/Dashboard/ShopScreens/ShopAdd';
import ShopEdit from './Screens/Dashboard/ShopScreens/ShopEdit';
import ShopDelete from './Screens/Dashboard/ShopScreens/ShopDelete';
import DashHome from './Screens/Dashboard/DashHome/DashHome';

function App() {
    return (
        <Routes>
            <Route path='/' element={<MainLayout />}>
                <Route index element={<Home />} />

                <Route path='about' element={<About />}>
                    <Route path='juli+juneau+bio' element={<Bio />} />=

                </Route>

                <Route path='glass' element={<Artwork />}>
                    <Route path='featured' element={<Featured />} />
                    <Route path='artist+at+work' element={<AtWork />} />
                    <Route path='find+juli+juneau' element={<Shows />} />
                    <Route path='awards' element={<Awards />} />
                    <Route path='happy+clients' element={<Clients />} />

                </Route>

                <Route path='memorials' element={<Memorials />} />
                <Route path='press' element={<Press />} />

                <Route path='installations' element={<Installations />} />

                <Route path='shop' element={<Shop />}>
                    <Route path='main' element={<ShopDisplay />} />
                    <Route path='main/:productId' element={<ItemDisplay />} />

                    {/* <Route path='checkout/:id' element={<Checkout />} /> */}

                    {/* <Route path='main' element={<ShopUnderConstruction />} /> */}

                </Route>


                <Route path='contact' element={<Contact />} />

                <Route path='login' element={<Login />} />

                <Route element={<PersistLogin />}>
                    <Route element={<RequireAuth />}>
                        <Route path='juli+dashboard' element={<DashLayout />}>
                            <Route index element={<DashHome />} />
                            {/* <Route path='shop+upload' element={<UploadInputs />} />
                            <Route path='preview' element={<PreviewHome />}>
                                <Route path=':id' element={<UploadPreview />} />
                            </Route> */}
                            <Route element={<ShopAdd />} path='add' />
                            <Route element={<ShopEdit />} path='edit' />
                            <Route element={<ShopDelete />} path='delete' />
                            <Route element={<ShopViewItems />} path='view' />

                            <Route element={<UploadSuccess />} path='upload/success' />
                            <Route element={<UploadFailure />} path='upload/failure' />

                            <Route element={<EditItem />} path='edit/item/:id' />

                        </Route>
                    </Route>
                </Route>


            </Route>
        </Routes >
    );
}

export default App;
