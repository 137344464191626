import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { SegmentedControl } from '@mantine/core';
import api from '../../api/axios';

const Shop = () => {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState(null);

    const [sortValue, setSortValue] = useState(0);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await api.get('/fetch-shop-items');

                let images = response.data
                for (let i = 0; i < images.length; i++) {
                    if  (images[i].image_url_arr[0].endsWith('.heic')) {
                        images[i].image_url_arr[0] = images[i].image_url_arr[0].replaceAll('.heic', '.jpg')
                    }
                }
                setItems(images);
                setLoading(false);
            } catch (err) {
                console.log(err.message);
                console.log(err.stack);
            }
        }
        fetchItems();
    }, [])

    return (
        <div className='flex flex-row w-full'>
            {!loading &&
                <Outlet
                    context={{ items }}
                />}
        </div>
    )
}

export default Shop