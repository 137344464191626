import React, { useEffect, useState } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import { Card, Image, Text, Badge, Button, Group, Pagination } from "@mantine/core";
import { createPages, handlePageChange, sliceData } from "../Shop_Functions/functions";
import useEmblaCarousel from 'embla-carousel-react'
import { ShopDisplayCarousel } from './Carousel_Components/ShopDisplayCarousel'

const ShopDisplay = () => {
    const { items } = useOutletContext();
    const navigate = useNavigate();
    const [emblaRef] = useEmblaCarousel()

    const [activePage, setActivePage] = useState(1);
    const [slicedItems, setSlicedItems] = useState(null)

    const numberOfPages = createPages(items);

    useEffect(() => {
        const handleScroll = () => {
            handlePageChange()
        }
        handleScroll()
    }, [activePage])

    useEffect(() => {
        sliceData(activePage, items, setSlicedItems);
    }, [activePage])

    console.log(items);

    return (
        <div className='flex w-full pb-8 justify-center'>
            <div className="w-full flex flex-col sm:max-w-[420px] md:max-w-[800px] max-w-[1100px]">
                <div className='text-white py-8 text-2xl ml-4 font-light uppercase'>
                    Shop
                </div>

                <div className="grid grid-cols-2 sm:grid-cols-1 auto-rows-max">
                    {slicedItems !== null && slicedItems.map((row) => (
                        <div className="p-4">
                            <Card shadow="sm" padding="lg" radius="md" className="">

                                <Card.Section>
                                    {/* {row.image_url_arr.length === 1 && */}
                                        <Image
                                            src={row.image_url_arr[0]}
                                            height={560}
                                            alt={row.title}
                                        /> 
                                        {/* } */}

                                    {/* {row.image_url_arr.length > 1 &&
                                        <ShopDisplayCarousel slides={row.image_url_arr} options={{}}/>
                                    } */}
                                </Card.Section>

                                <Group position="apart" mt="md" mb="xs">
                                    <Text weight={600}>{row.title}</Text>
                                </Group>

                                <Button
                                    variant="light"
                                    fullWidth
                                    mt="md"
                                    radius="xl"
                                    classNames={{ root: 'bg-[#59656F] hover:bg-[#59656F] hover:opacity-75', label: 'text-white uppercase' }}
                                    onClick={() => navigate(`${row.id}`)}
                                >
                                    Explore
                                </Button>
                            </Card>
                        </div>

                    ))}
                </div>

                <div className='flex justify-center items-end mt-4'>
                    <Pagination
                        value={activePage}
                        onChange={setActivePage}
                        total={numberOfPages}
                        radius='xl'
                        classNames={{ control: 'bg-[#59656F] data-[active=true]:bg-[#9C528B]' }}
                    />
                </div>

            </div>

        </div>
    )
}

export default ShopDisplay