
const items = [
    {
        "product_type": "vases",
        "description": "Hand blown glass vase in shades of aquamarine blue, and emerald green frit with white powder. Vase blown shaped then cut when hot, cut edge rolled in tiny crumbles of Cold Clear Pieces of glass, dusted white glass powder. Coldworked, signed, Numbered. ",
        "price": 795,
        "title": "Aquamarine Vase",
        "image_url": "https://res.cloudinary.com/dp22pyubg/image/upload/v1700898602/Shop/aquamarine-blue_ypjbpv.jpg",
        "size": "19\" X 7\" X 6\"",
        "order": 1,
        "id": 10
    },
    {
        "product_type": "glasses",
        "description": "Hand blown, and hand shaped. Stem and foot sculpted when making. A variety of colors, typically including bronze/silver, which is the last step one making, fire is blasted on the wine glass. Inquire for specific colors.",
        "price": 75,
        "title": "Handblown Wine Glasses",
        "image_url": "https://res.cloudinary.com/dp22pyubg/image/upload/v1700898602/Shop/wine-glasses_b8jj4s.jpg",
        "size": "9\" X 4\" X 0\"",
        "order": 2,
        "id": 2
    },
    {
        "product_type": "oblongs",
        "description": "Hand blown/sculpted, 4 colors are applied in four layers… Then blown, cut,cork blocks/wet newspaper, tools to shape. As I heat on pipe for final spin shape, I apply fire to piece, then fit color I had applied on my outside will turn bronze, gold silver.",
        "price": 749.99,
        "title": "Petra Oblong",
        "image_url": "https://res.cloudinary.com/dp22pyubg/image/upload/v1700898601/Shop/petra-oblong_bh4hne.jpg",
        "size": "9\" X 23\" X 7\"",
        "order": 3,
        "id": 8
    },
    {
        "product_type": "oblongs",
        "description": "Three layers of each color of frit. Beautiful water and sky blues, tomato red, sunset orange- encased in clear. Then bronze/silver color frit applied to outside. Heated and Spun while shooting fire on the piece to bring out the silver color on outside. ",
        "price": 550,
        "title": "Caribbean Oblong",
        "image_url": "https://res.cloudinary.com/dp22pyubg/image/upload/v1700898603/Shop/caribean-oblong_apmgvz.jpg",
        "size": "8\" X 22\" X 8\"",
        "order": 4,
        "id": 9
    },
    {
        "product_type": "oblongs",
        "description": "Hand blown/sculpted, Three colors are applied in four layers… Then blown, cut,cork blocks/wet newspaper, tools to shape. As being heated on punty for final spin shape, I apply fire & gold color on outside becomes silver. ",
        "price": 650,
        "title": "Chocolate and White Oblong",
        "image_url": "https://res.cloudinary.com/dp22pyubg/image/upload/v1700898604/Shop/choclate_kgjavk.jpg",
        "size": "7\" X 8\" X 22\"",
        "order": 5,
        "id": 1
    },
    {
        "product_type": "vases",
        "description": "Tortoiseshell brown, sandy beige, jade, three layers of each color, hand blown, doubled lip wrap around whole piece. Spun, coldworked signed and numbered",
        "price": 650,
        "title": "Jade and Tortoise Bowl",
        "image_url": "https://res.cloudinary.com/dp22pyubg/image/upload/v1700898600/Shop/jade-bowl_xrr0xd.jpg",
        "size": "7\" X 22\" X 22\"",
        "order": 6,
        "id": 7
    },
    {
        "product_type": "bowls",
        "description": "Hand blown glass,custom designed, large frit in 3 shades of blue color combinations, shaped with metal wooden and paper tools, wrapped with hot aquamarine glass vines . Cold worked, signed numbered attached to wooden award bases.",
        "price": 550,
        "title": "Custom Hand Blown Glass Awards",
        "image_url": "https://res.cloudinary.com/dp22pyubg/image/upload/v1700898599/Shop/handblownglass_vstbl0.jpg",
        "size": "14\" X 10\" X 0\"",
        "order": 7,
        "id": 3
    },
    {
        "product_type": "vases",
        "description": "Hand blown glass vase. Sky light blue and coral sunset colors. Created blowing, sculpting, stretching. Signed and numbered, bottom is ground flat and smooth with diamond wheels.",
        "price": 650,
        "title": "Caribbean Vase",
        "image_url": "https://res.cloudinary.com/dp22pyubg/image/upload/v1700898604/Shop/caribean-vase_rwqadw.jpg",
        "size": "18\" X 8\" X 8\"",
        "order": 8,
        "id": 4
    },
    {
        "product_type": "bowls",
        "description": "3 shades red, powders/Frits, silver reducing glass color in thick crazy wrap on outside,blown,lip wrap same silver reducing color.While heating for final spin, fire is shot heavily/constantly in glory hole while turning to create silver.  Choose color design.",
        "price": 650,
        "title": "Large bowl, Red",
        "image_url": "https://res.cloudinary.com/dp22pyubg/image/upload/v1700898600/Shop/large-red-bowl_okla8k.jpg",
        "size": "16\" X 18\" X 0\"",
        "order": 9,
        "id": 5
    },
    {
        "product_type": "globes",
        "description": null,
        "price": 97,
        "title": "Garden Globes",
        "image_url": "https://res.cloudinary.com/dp22pyubg/image/upload/v1701045607/Shop/pcpwnkqe5cbvz5essmqb.png",
        "size": "-",
        "order": 10,
        "id": 6
    },
    {
        "product_type": "globes",
        "description": null,
        "price": 97,
        "title": "Garden Globes",
        "image_url": "https://res.cloudinary.com/dp22pyubg/image/upload/v1701045607/Shop/pcpwnkqe5cbvz5essmqb.png",
        "size": "-",
        "order": 10,
        "id": 6
    },
    {
        "product_type": "globes",
        "description": null,
        "price": 97,
        "title": "Garden Globes",
        "image_url": "https://res.cloudinary.com/dp22pyubg/image/upload/v1701045607/Shop/pcpwnkqe5cbvz5essmqb.png",
        "size": "-",
        "order": 10,
        "id": 6
    },
    {
        "product_type": "globes",
        "description": null,
        "price": 97,
        "title": "Garden Globes",
        "image_url": "https://res.cloudinary.com/dp22pyubg/image/upload/v1701045607/Shop/pcpwnkqe5cbvz5essmqb.png",
        "size": "-",
        "order": 10,
        "id": 6
    },
]

export const createPages = (items) => {
    let numberOfPages = null

    if (items.length % 6 === 0) numberOfPages = items.length / 6

    if (items.length % 6 !== 0) numberOfPages =  Math.ceil(items.length / 6) 

    return numberOfPages
};

export const handlePageChange = (setActivePage) => {
    window.scrollTo(0,0);

    return setActivePage
};

export const sliceData = (activePage, items, setSlicedItems) => {
    let firstSlice;
    let secondSlice;

    if (activePage === 1) {
        firstSlice = 0
    } else {
        firstSlice = (activePage - 1) * 6
    }

    secondSlice = firstSlice + 6


    setSlicedItems(items.slice(firstSlice, secondSlice))
};