import React, { useEffect, useState } from 'react';
import { generatePath, useParams, useNavigate } from 'react-router-dom';
import { Button, Card } from '@mantine/core';
import api from '../../../api/axios';
import { IconArrowNarrowLeft } from '@tabler/icons-react';
import useEmblaCarousel from 'embla-carousel-react'
import { ItemDisplayCarousel } from './Carousel_Components/ShopDisplayCarousel'

const ItemDisplay = () => {
    const { productId } = useParams();
    const navigate = useNavigate();
    const [details, setDetails] = useState();
    const [loading, setLoading] = useState(true);

    const fetchItemDetailsPath = generatePath('/fetchitemdetails/:productId', {
        productId: productId
    });

    useEffect(() => {
        const fetchItemDetails = async () => {
            try {
                const response = await api.get(fetchItemDetailsPath);
                setDetails(response.data);
                setLoading(false);
            } catch (err) {
                console.log(err.message);
            }
        }
        fetchItemDetails();
    }, []);

    return (
        <div className='flex w-full justify-center pb-8'>
            {!loading
                ? details.map((item) => (
                    <div className='max-w-[380px] 532:max-w-[500px] 820:max-w-[790px] flex flex-col text-zinc-500 p-4'>

                        <div className='cursor-pointer flex flex-row hover:text-white pt-4' onClick={() => { navigate('/shop/main') }}>
                            <IconArrowNarrowLeft />
                            <div>Shop</div>
                        </div>

                        <div className='text-white pt-4 uppercase text-xl font-semibold tracking-wider'>
                            {item.title}
                        </div>

                        <div className='text-white pt-2 uppercase text-sm font-semibold'>
                            $ {item.price}
                        </div>

                        <Card shadow="sm" radius="md" className='flex justify-center p-0 bg-black'>
                            <Card.Section >
                                <div className='flex items-center'>
                                    {/* {item.image_url_arr.length === 1 && */}
                                        <img
                                            src={item.image_url_arr[0]}
                                            alt={item.title}
                                            className='max-w-[380px] 532:max-w-[500px] 820:max-w-[790px] h-auto w-full'
                                        />
                                        {/* } */}

                                    {/* {item.image_url_arr.length > 1 &&
                                        <ItemDisplayCarousel slides={item.image_url_arr} options={{}} />
                                    } */}
                                </div>
                            </Card.Section>
                        </Card>

                        <div className='pt-8 flex flex-col'>
                            <div className='text-white font-semibold'>
                                Description
                            </div>
                            <div className='pt-2 text-[14px]'>
                                {item.size}
                            </div>
                            <div className='tracking-tight pt-2 text-[16px]'>
                                {item.description}
                            </div>
                        </div>

                        <div className='pt-4 w-full'>
                            <Button
                                color="gray"
                                uppercase
                                radius="xs"
                                classNames={{ root: 'w-full' }}
                                // onClick={() => navigate(`/shop/checkout/${productId}`)}
                                onClick={() => {}}
                            >
                                Purchase
                            </Button>
                        </div>

                    </div>
                ))
                : <></>}
        </div>
    )
}

export default ItemDisplay